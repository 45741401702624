import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";



const menu = [
  {
    name: "Dashboard",
    to: "/dashboard",
    icon: <DashboardIcon fontSize="medium" />,
    active: "dashboard",
    hasSubMenu: false,
    submenuList: null,
  },

  {
    name: "SKU Look Up",
    to: "/sku ",
    icon: <ManageAccountsIcon fontSize="medium" />,
    active: "Settings",
   
  },
];

export default menu;
