// App.js
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import LoginForm from "./Components/Loginflow/LoginForm";
import SideBarToggle from "./Components/Layouts/SideBarToggle";
import Footer from "./Components/Layouts/Footer";
import Main from "./Components/MainComponents/Main";
import Dashboard from "./Components/MainComponents/Dashboard";

function App() {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Routes>
        <Route path="/" element={<LoginForm />} />

        <Route
          element={
            <SideBarToggle isOpen={isOpen} toggleSidebar={toggleSidebar} />
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/sku" element={<Main />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
