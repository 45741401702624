import React, { useState, useEffect } from "react";

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const project = "Project Leap ®";

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000); // Update every minute to catch potential changes in year
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <footer className="footer">
        <span>
        Copyright   &copy; {currentYear} <a href="#">{project}</a>.
        </span>
      </footer>
    </div>
  );
}

export default Footer;
