import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Form } from "react-bootstrap";
function Dashboard() {
  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "800px",
      }}
    >
      <div>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKLiyn_fFu3st717ftzY3HFk5pj3NZvcNggzohVrNBpJcvLf1VqqoA8PPDA3Sqk0QgD3U&usqp=CAU"
          className="img-fluid"
          alt="Coming Soon"
          style={{ width: "300px", background: "white" }}
        />
      </div>
    </Container>
  );
}

export default Dashboard;
