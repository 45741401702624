import React from "react";
import { Button, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import profile from '../Assets/images/profile.svg';
import green from '../Assets/images/active.svg';
function Header() {
  return (
    <div className="header ">
      <Navbar
        expand="lg"
        className="bg-body-tertiary page-top-bar"
        style={{ height: "58px" }}
      >
        <form className="header-search"></form>

        <Nav className="menu-value justify-content-end">
          <NavDropdown
            className="w-full"
            title={
              <div className="profile-icon">
                <div className="profile-img">
                  <img src={profile} alt="img" />
                  <i>
                    {" "}
                    <img
                      src={green}
                      alt="drop icon"
                      style={{ width: "10px", background: "transparent" }}
                    />
                  </i>
                </div>
                admin
                <span>
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    style={{ fontSize: "13px" }}
                  />
                </span>
              </div>
            }
            id="navbarScrollingDropdown"
          >
            <NavDropdown.Item className="dropdown-item" href="#">
              Profile
            </NavDropdown.Item>

            <NavDropdown.Item className="dropdown-item" href="#">
              Notifications
            </NavDropdown.Item>

            <div className="divider"></div>
            <NavDropdown.Item className="dropdown-item" href="/">
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar>
    </div>
  );
}

export default Header;
