
import React from "react";
import Layout from "./Layout";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";
import "./Layout.css";

function SideBarToggle({ isOpen, toggleSidebar }) {
  return (
    <>
      <Header />
      <FontAwesomeIcon
        icon={faBars}
        onClick={toggleSidebar}
        className="toggle mt-2"
        size="2x"
      />
      <div className={`layout-fixed ${isOpen ? "open" : ""}`}>
        <Layout />
      </div>
      <div
        className={`outlet-layout ${isOpen ? "shifted" : ""}`}
        
      >
        <Outlet />
      </div>
      <div className={`outlet-layout ${isOpen ? "shifted" : ""}`}>
        <Footer />
      </div>
    </>
  );
}

export default SideBarToggle;
