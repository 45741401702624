const fetchSKUData = async (inputValue) => {
    try {
      const response = await fetch(
        `https://projectleapapi.agilensmartservices.com/SkuUrl/${inputValue}`
      );
  
      if (!response.ok) {
        if (response.status === 400) {
          throw new Error("Bad request: Please check your input");
        } else {
          throw new Error("Failed to fetch data");
        }
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };
  
  export { fetchSKUData };
  

  const login = async (email, password) => {
    try {
      const response = await fetch(
        "https://projectleapapi.agilensmartservices.com/Login",
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            UserName: email,
            Password: password
          })
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();

        throw new Error(errorData.message || "Failed to login.");
      }
  
      return await response.json();
    } catch (error) {
      throw error;
    }
  };
  
  export { login };
  