import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col, Form, Table } from "react-bootstrap";
import { fetchSKUData } from "../Services/Api";

import CustomButton from "../Button/CustomButton ";
import SpinnerComponent from "../Spinner/Spinner";

export default function Main() {
    const [inputValue, setInputValue] = useState("");
    /*const [apiResponse, setApiResponse] = useState("");*/
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    //const [store, setStore] = useState("");
    //const [price, setPrice] = useState("");
    //const [buyBoxCurrent, setBuyBoxCurrent] = useState("");
    //const [buyBoxStock, setBuyBoxStock] = useState("");
    const [apiData, setApiData] = useState([]);


    useEffect(() => {
        AOS.init({
            offset: 200,
            delay: 0,
            duration: 1000,
            easing: "ease",
            once: true,
            mirror: false,
        });
    }, []);

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await fetchSKUData(inputValue);
            if (data) {
                setApiData(data);
            }
            //setApiResponse(data.URL);
            //setStore(data.Store);
            //setPrice(data.Price);
            //setBuyBoxCurrent(data.BuyBoxCurrent);
            //setBuyBoxStock(data.BuyBoxStock);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className="d-flex justify-content-center align-items-center m-3 border-none"
            style={{ minHeight: "500px" }}
        >
            <Container className="text-start" fluid>
                <Row>
                    <Col xs={12} md={6} data-aos="fade-up" data-aos-delay="200">
                        <h1 className="responsive-text">SKU</h1>
                        <Form.Group>
                            <Form.Control
                                placeholder="Enter your SKU"
                                type="text"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                className=""
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="py-2">
                    <Col xs={12} md={6} className="text-end">
                        <CustomButton
                            onClick={handleSubmit}
                            className="text-black p-2 rounded border"
                            disabled={loading}
                        >
                            {loading ? "Loading..." : "Look Up"}
                        </CustomButton>
                    </Col>
                </Row>
                <div className="text-start py-4"></div>

                {error && <div className="text-red-600 text-left ">{error}</div>}
                <div className="">
                    {apiData && !loading && (
                        <Table bordered striped hover responsive>
                            <tbody>
                                {apiData.map((item, index) => (
                                    <tr key={index}>
                                        {item.Name === 'URL' ? (
                                            <>
                                                <th>Result</th>
                                                <td>
                                                    <a
                                                        href={item.Value}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="underline p-2 rounded"
                                                    >
                                                        {item.Value}
                                                    </a>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <th>{item.Name}</th>
                                                <td>{item.Value}</td>
                                            </>
                                        )}
                                    </tr>
                                ))}

                            </tbody>
                        </Table>
                    )}
                </div>
            </Container>
        </div>
    );
}
