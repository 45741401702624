import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import { login } from "../Services/Api";
import Footer from "../Layouts/Footer";
import "./login.css";
import { notification } from "antd";

const LoginForm = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setLoading(true);
      try {
        const response = await login(email, password);
        console.log(response);
        if (response) {
          navigate("/dashboard");
          notification.success({
            message: "Success",
            description: "You have successfully logged in admin",
            duration: 0.5,
          });
        } else {
          throw new Error(response?.data?.message || "Failed to log in.");
        }
      } catch (error) {
        setError("Failed to log in.");
        notification.warning({
          message: "Warning",
          description: "Invalid",
          duration: 0.5,
        });
        console.error("Login error:", error);
      }
      setLoading(false);
    }
    setValidated(true);
  };

  return (
    <div className="login-box mt-5 mb-5">
      <div className="contanier-type1">
        <div className="box-body">
          <div className="white-box">
            <h2 className="brand-logo text-center p-3">Project Leap</h2>
            <Form onSubmit={handleSubmit} noValidate validated={validated}>
              <Form.Group className="mb-3" controlId="validationCustom01">
                <Form.Control
                  type="name"
                  placeholder="Name"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className="custom-input" 
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email.
                </Form.Control.Feedback>
              </Form.Group>

              <div className="mb-0 password-cont">
                <Form.Group className="mb-4" controlId="validationCustom02">
                  <Form.Control
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    className="custom-input" 
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your password.
                  </Form.Control.Feedback>
                  <span
                    className="show-password"
                    onClick={togglePasswordVisibility}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={isPasswordVisible ? faEye : faEyeSlash}
                    />
                  </span>
                </Form.Group>
              </div>

              <div className="d-grid">
                <Button
                  className="btn-primary-login"
                  type="submit"
                  style={{ height: "50px", fontSize: "16px" }}
                >
                  {!loading ? (
                    "Log in"
                  ) : (
                    <div className="spinner-box">
                      <div className="pulse-container">
                        <div className="pulse-bubble pulse-bubble-1"></div>
                        <div className="pulse-bubble pulse-bubble-2"></div>
                        <div className="pulse-bubble pulse-bubble-3"></div>
                      </div>
                    </div>
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginForm;
