import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import menu from "./Menu"; 

function Layout() {
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
  const [activeSubmenuItem, setActiveSubmenuItem] = useState(null);

  useEffect(() => {
    const storedIndex = localStorage.getItem("openSubMenuIndex");
    const storedActiveSubmenuItem = localStorage.getItem("activeSubmenuItem");
    if (storedIndex !== null) {
      setOpenSubMenuIndex(parseInt(storedIndex));
    }
    if (storedActiveSubmenuItem !== null) {
      setActiveSubmenuItem(storedActiveSubmenuItem);
    }
  }, []);

  const handleSubMenuClick = (index) => {
    setOpenSubMenuIndex((prevIndex) => (prevIndex === index ? null : index));
    localStorage.setItem("openSubMenuIndex", index);
  };

  const handleSubMenuItemClick = (submenuTo) => {
    setActiveSubmenuItem(submenuTo);
    localStorage.setItem("activeSubmenuItem", submenuTo);
  };
  return (
    <div className="layout">
      <div className="side">
        <div className="header-wrapper">
          <div className="brand-logo">
            <h4 className="logolarge text-center">Project Leap</h4>
          </div>
        </div>
        <nav>
          {menu.map((menuItem, index) => (
            <ul className="menus" key={index}>
              <li
                className={`menu-items ${
                  window.location.pathname === menuItem.to ||
                  (menuItem.submenuList &&
                    menuItem.submenuList.some(
                      (submenu) => window.location.pathname === submenu.to
                    ))
                    ? "active"
                    : ""
                }`}
                onClick={() => handleSubMenuClick(index)}
              >
                <Link to={menuItem.to}>
                  <span className="ms-2">
                    <p className="p-0 m-0 mx-1 icon-items">{menuItem.icon}</p>{" "}
                    {menuItem.name}
                  </span>
                </Link>
                <span>
                
                </span>
              </li>

              {openSubMenuIndex === index &&
                menuItem.submenuList &&
                menuItem.submenuList.map((submenu, subIndex) => (
                  <ul className="submenu-dropdown mt-2" key={subIndex}>
                    <Link
                      to={submenu.to}
                      style={{ textDecoration: "none", color: "white" }}
                      onClick={() => handleSubMenuItemClick(submenu.to)}
                    >
                      <li
                        className={`menu-items ${
                          window.location.pathname === submenu.to ||
                          activeSubmenuItem === submenu.to 
                            ? "active"
                            : ""
                        }`}
                      >
                        <span style={{ fontSize: "13px" }}>
                          {" "}
                          {/* <NavigateNextIcon /> */}
                          {submenu.name}
                        </span>
                      </li>
                    </Link>
                  </ul>
                ))}
            </ul>
          ))}
        </nav>
      </div>
    </div>
  );
}

export default Layout;
